import { init } from "ityped";
import { useEffect, useRef } from "react";
import "./intro.scss";

export default function Intro() {

  const textRef = useRef()

  useEffect(() => {

      init(textRef.current, {
        showCursor: false,
        backDelay: 1500,
        backSpeed: 60,
        strings: ["Back-end", "Front-end"],
      });

  },[])
  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src="assets/eu_foto.png"></img>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi There, I'm</h2>
          <h1>Hideki Maesaka</h1>
          <h3>Web Developer <span ref={textRef}></span></h3>
        </div>
        <a href="#portfolio">
          <img src="assets/down.png" alt="down arrow" />
        </a>
      </div>
    </div>
  );
}
